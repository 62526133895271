import React from "react"
import { Stack, Box, Typography } from "@mui/material"
import HiLiteLetter from "./HiLiteLetter"

const LogoExtend = ({ color = "#fff" }: any) => {
  const basicStyle = {
    // fontSize: "1rem",
    color,
  }

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "baseline",
      }}
    >
      <HiLiteLetter color={color} letter="A" />
      <Typography sx={basicStyle}>merican</Typography>
      <HiLiteLetter color={color} letter="H" />
      <Typography sx={basicStyle}>elp for</Typography>
      <HiLiteLetter color={color} letter="L" />
      <Typography sx={basicStyle}>ebanon</Typography>
      <HiLiteLetter color={color} letter="A" />
      <Typography sx={basicStyle}>ssociation</Typography>
    </Stack>
  )
}

export default LogoExtend
