import moment from "moment"

export const mapPaymentData = (data: any[]) => {
  return data
    .filter((item: any) => item.status === "succeeded")
    .map((item: any) => {
      return {
        id: item.id,
        amount: item.amount / 100,
        currency: item.currency,
        date: moment(new Date(item.created * 1000)).format("MM/DD/YYYY"),
        email: item.Email,
        customer: item.customer,
        requireReceipt: item.metadata?.requireTaxReceipt || "false",
        year: new Date(item.created * 1000).getFullYear(),
      }
    })
}
