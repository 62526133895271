import { createAsyncThunk } from "@reduxjs/toolkit"
import { mapPaymentData } from "utils/helper"
import { DataStatus } from "../types/uiBaseType"
import { getConfig } from "../utils/environment"
import http from "../utils/http/axiosProxy"

const { apiUrl } = getConfig()

export const getClientSecret = createAsyncThunk<
  any,
  any,
  { rejectValue: DataStatus }
>(
  "payment/getClientSecret",
  async ({ name, email, currency, amount, requireReceipt }, thunkApi) => {
    try {
      const response = await http.post(
        "v1/payment/secret",
        { name, email, currency, amount, requireReceipt },
        { baseURL: apiUrl }
      )
      const { data, status, statusText } = response
      const {
        client_secret: clientSecret,
        currency: cur,
        amount: amt,
        email: userEmail,
      } = data

      return { clientSecret, email: userEmail }
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        errorMessage: error.message || error,
      })
    }
  }
)

export const getPayments = createAsyncThunk<
  any,
  any,
  { rejectValue: DataStatus }
>("payment/getPayments", async (_, thunkApi) => {
  try {
    const response = await http.get(
      "v1/payment/paymentIntents",
      {},
      { baseURL: apiUrl }
    )
    const { data, status, statusText } = response

    return { data: mapPaymentData(data.data), hasMore: data.has_more }
  } catch (error: any) {
    return thunkApi.rejectWithValue({
      errorMessage: error.message || error,
    })
  }
})

export const getCustomers = createAsyncThunk<
  any,
  any,
  { rejectValue: DataStatus }
>("payment/customers", async (_, thunkApi) => {
  try {
    const response = await http.get(
      "v1/payment/customers",
      {},
      { baseURL: apiUrl }
    )
    const { data, status, statusText } = response

    return { data: data.data, hasMore: data.has_more }
  } catch (error: any) {
    return thunkApi.rejectWithValue({
      errorMessage: error.message || error,
    })
  }
})
