import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = {}

const lookupSlice = createSlice({
  name: "lookup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /** */
  },
})

export default lookupSlice.reducer
