import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material"
import ButtonControl from "components/controls/ButtonControl"
import InfoCard from "components/controls/InfoCard"
import { updateUserProfile } from "reducers/user"
import { getConfig } from "utils/environment"
import { AppDispatch } from "utils/stateManagement/store"

const validationRules = {
  name: { required: true },
  email: { required: true },
}

function emailIsValid(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

const UserInfo = (): JSX.Element => {
  const navigate = useNavigate()

  const [form, setForm] = React.useState<any>({
    name: "",
    email: "",
  })
  const [checked, setChecked] = React.useState(true)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  useEffect(() => {
    const userInfo = localStorage.getItem("userinfo")
    if (userInfo) {
      const userObj = JSON.parse(userInfo)
      setForm(userObj)
    }
  }, [])
  const onClick = React.useCallback(() => {
    const userObj = {
      email: form.email,
      name: form.name,
      requireReceipt: checked,
    }

    localStorage.setItem("userinfo", JSON.stringify(userObj))
    AppDispatch(updateUserProfile(userObj))
    navigate("../payment")
  }, [form, checked])

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      const name = e.target.name
      const rule = validationRules[name]
      let valid = true
      if (rule) {
        if (rule.required) {
          if (!value) valid = false
        }
      }

      setForm((prevState) => ({
        ...prevState,
        [name]: value,
        disabled: !valid,
      }))
    },
    []
  )

  const onkeyDown = (e: any, maxLength: number) => {
    if (e.target.value.length > maxLength) {
      e.nativeEvent.preventDefault()
    }
  }

  const isEmailValid = emailIsValid(form.email)
  const isDisabled = !form.name || !isEmailValid
  return (
    <Stack sx={{ background: "green" }}>
      <InfoCard>
        <Stack direction="column" spacing={2}>
          <Stack>
            <TextField
              required
              error={!form.email}
              label="Email"
              name="email"
              type="email"
              value={form.email || ""}
              variant="outlined"
              onChange={onChange}
              onKeyDown={(e: any) => {
                onkeyDown(e, 70)
              }}
            />
            {!form.email ? (
              <FormHelperText sx={{ color: "red" }}>
                Required Valid Email
              </FormHelperText>
            ) : (
              <></>
            )}
          </Stack>

          <Stack>
            <TextField
              required
              error={!form.name}
              label="Name"
              name="name"
              type="text"
              value={form.name || ""}
              variant="outlined"
              onChange={onChange}
              onKeyDown={(e: any) => {
                onkeyDown(e, 70)
              }}
            />
            {!form.name ? (
              <FormHelperText sx={{ color: "red" }}>
                required field
              </FormHelperText>
            ) : (
              <></>
            )}
          </Stack>
          <Stack>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} />}
                label="Email me the receipt for tax deduction"
              />
            </FormGroup>
          </Stack>
          <ButtonControl
            disabled={isDisabled}
            label="Continue"
            width="100px"
            onClick={onClick}
          />
        </Stack>
      </InfoCard>
    </Stack>
  )
}

export default React.memo(UserInfo)
