import React from "react"
import { Stack, Box, Typography } from "@mui/material"

const HiLiteLetter = ({ letter, color }: any) => {
  return (
    <Typography
      sx={{
        color,
        fontWeight: "bold",
        // fontSize: "1.2rem",
        marginLeft: "4px",
      }}
    >
      {letter}
    </Typography>
  )
}

export default HiLiteLetter
