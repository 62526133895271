import { getCustomers, getPayments } from "services/paymentService"
import { UserState } from "./user"
import type { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit"

export const customersBuilder = (
  builder: ActionReducerMapBuilder<UserState>
) => {
  builder
    .addCase(getCustomers.pending, (state: UserState) => {
      state.isCustomersLoading = true
      state.isCustomersReady = false
    })
    .addCase(
      getCustomers.fulfilled,
      (state: UserState, action: PayloadAction<any>) => {
        const { data, hasMore } = action.payload

        return {
          ...state,
          customersData: data,
          isCustomersLoading: false,
          isCustomersReady: true,
        }
      }
    )
    .addCase(getCustomers.rejected, (state, action) => {
      state.hasError = true
      state.isCustomersLoading = false
      state.errorMessage = action.payload
        ? action.payload.errorMessage
        : action.error.message
    })
}
