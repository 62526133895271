import React, { memo } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { Box, Stack, Typography, useTheme } from "@mui/material"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import LogoExtend from "components/controls/LogoExtend"
import Navbar from "components/controls/Navbar"
import { appTheme } from "../../styles/mui-themes"
import BottomNavbar from "../controls/BottomNavbar"

const style = (theme) => ({
  display: "none",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
  },
})

const SiteLayout = (): JSX.Element => {
  const theme = useTheme()
  const path = useLocation()
  const isHome = path.pathname.includes("home")
  const bgVariant = isHome ? "bg" : ""
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <Stack className={bgVariant}>
        <Stack>
          <Navbar />
          <Container maxWidth="sm" sx={{ paddingBottom: "60px" }}>
            <Stack sx={style(theme)}>
              <LogoExtend color="#fff" />
            </Stack>
            <Stack
              sx={{
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              <Outlet />
            </Stack>
            <Stack sx={{ alignItems: "center", marginTop: "10px" }}>
              <Typography sx={{ fontSize: "11px" }}>
                Copyright © 2024. All Rights Reserved by AHLA
              </Typography>
            </Stack>
          </Container>
        </Stack>
        <BottomNavbar />
      </Stack>
    </ThemeProvider>
  )
}

export default memo(SiteLayout)
