import React, { useEffect } from "react"
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom"
import "@fontsource/roboto"
import Admin from "pages/Admin"
import Gallery from "pages/Gallery"
import UserInfo from "pages/UserInfo"
import { updateUserRole } from "reducers/user"
import { AppDispatch } from "utils/stateManagement/store"
import SiteLayout from "./components/layout/SiteLayout"
import DonateOptions from "./pages/DonateOptions"
import Events from "./pages/Events"
import Home from "./pages/Home"

const App = (): JSX.Element => {
  useEffect(() => {
    const adminUser = localStorage.getItem("adminuser")
    if (adminUser) {
      const userObj = JSON.parse(adminUser)
      AppDispatch(updateUserRole(userObj))
    }
  }, [])
  return (
    <Router>
      <Routes>
        <Route element={<SiteLayout />}>
          <Route element={<Navigate replace to="/home" />} path="/" />
          <Route element={<Home />} path="/home" />
          {/* <Route element={<PayPal />} path="/paypal" /> */}
          {/* <Route element={<Profile />} path="/profile" /> */}

          <Route element={<UserInfo />} path="/userinfo" />
          <Route element={<Gallery />} path="/gallery" />
          <Route element={<Admin />} path="/admin" />
          <Route element={<Events />} path="/events" />
          <Route element={<DonateOptions />} path="/options" />
          {/* <Route element={<RequireAuth />}>
            <Route element={<Report />} path="/report" />
          </Route> */}
        </Route>
      </Routes>
    </Router>
  )
}

export default App
