import { createTheme } from "@mui/material/styles"

const themeColors = {
  mauve: "#d11947",
  mauveLight: "#f1e6fc",
  beige: "#f7f2ed",
  beigeDark: "#edded1",
  blue: "#b5e3e8",
  blueLight: "#c4e8ed",
  blueLighter: "#d1edf2",
  orange: "#ff5200",
  purpleDark: "#332145",
  white: "#ffffff",
}

export const appTheme = createTheme({
  typography: {
    allVariants: {
      color: themeColors.purpleDark,
    },
    button: {
      textTransform: "none",
    },
  },

  palette: {
    background: {
      default: themeColors.beige,
      paper: themeColors.beige,
    },
    primary: {
      main: themeColors.mauve,
      light: themeColors.mauveLight,
    },
    secondary: {
      main: themeColors.blue,
      light: themeColors.blueLight,
      dark: themeColors.beigeDark,
    },
    info: {
      main: themeColors.blueLighter,
    },
    text: {
      primary: themeColors.purpleDark,
      secondary: themeColors.purpleDark, // designs have one text color
    },
    action: {
      active: themeColors.purpleDark,
      selected: themeColors.orange,
    },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: themeColors.white,
          background: theme.palette.action.active,
          height: "40px",
          padding: "8px",
          borderRadius: "8px",
          fontSize: "1.5rem",
          width: "40px",
          "&.Mui-selected": {
            backgroundColor: theme.palette.action.selected,
            color: themeColors.white,
            "&:hover": {
              backgroundColor: theme.palette.action.selected,
              color: themeColors.white,
            },
          },
          "&:hover": {
            backgroundColor: theme.palette.action.selected,
            fontSize: "1.5rem",
            color: themeColors.white,
          },
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // disableRipple: true,
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.severity === "info" && {
            color: theme.palette.text.primary,
          }),
        }),
      },
    },
  },
})
