import { current } from "@reduxjs/toolkit"
import { RootState } from "reducers"
import { UserState } from "reducers/user"
import { createSelector } from "reselect"

const userData = (state: RootState): UserState => state.user || {}

export const getUserInfo = createSelector(userData, (user) => {
  return {
    userProfile: user?.data?.userProfile || {},
    isClientSecretLoading: user?.isClientSecretLoading || false,
    isClientSecretReady: user?.isClientSecretReady || false,
    clientSecret: user?.data?.clientSecret || "",
    amount: user?.data.amount || "",
    currency: user?.data.currency || "",
    requireReceipt: user?.data.requireReceipt || false,
  }
})

export const getUserRole = createSelector(userData, (user) => {
  return {
    isAdmin: !!user?.data?.userProfile?.isAdmin,
  }
})

export const getPaymentsState = createSelector(userData, (user) => {
  return {
    paymentsData: user?.paymentsData || [],
    customersData: user?.customersData || [],
    isLoading: user?.isPaymentsLoading || false,
  }
})
