import React from "react"
import { useNavigate } from "react-router-dom"
import { Stack, Typography } from "@mui/material"
import InfoCard from "components/controls/InfoCard"
import PayPalForm from "components/PayPalForm"
import bank from "../assets/images/Boa.png"
import zelle from "../assets/images/zelle.png"

const DonateOptions = () => {
  const navigate = useNavigate()
  const donate = () => {
    // const targetRoute = "../userinfo"
    // navigate(targetRoute)
    document.forms["paypalform"].submit()
  }

  return (
    <Stack gap={1} sx={{ position: "relative" }}>
      <PayPalForm />
      <InfoCard>
        <Stack
          gap={2}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography component="div">
            <input
              alt="Donate with PayPal button"
              name="submit"
              src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
              title="PayPal - The safer, easier way to pay online!"
              type="image"
              onClick={donate}
            />
            <img
              alt=""
              height="1"
              src="https://www.paypal.com/en_US/i/scr/pixel.gif"
              width="1"
            />
          </Typography>

          {/* <ButtonControl label="DONATE" onClick={donate} /> */}
        </Stack>
      </InfoCard>
      <InfoCard>
        <Typography component="div">
          <img alt="zelle logo" height="70px" src={zelle} width="100px" />
        </Typography>
        <Typography>
          <span style={{ fontWeight: "bold", marginRight: "5px" }}>Email:</span>
          <a href="mailto:ahlalebnen@gmail.com">ahlalebnen@gmail.com</a>
        </Typography>
      </InfoCard>
      <InfoCard>
        <Stack gap={2} sx={{ flexDirection: "row", alignItems: "center" }}>
          <Typography component="div">
            <img alt="bank of america" height="32px" src={bank} width="100%" />
          </Typography>
        </Stack>

        <Typography component="p" sx={{ fontWeight: "bold" }}>
          Routing #: 121000358
        </Typography>
        <Typography component="p" sx={{ fontWeight: "bold" }}>
          Acct#: 325194176130
        </Typography>
      </InfoCard>
    </Stack>
  )
}

export default DonateOptions
