import { getPayments } from "services/paymentService"
import { UserState } from "./user"
import type { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit"

export const paymentsBuilder = (
  builder: ActionReducerMapBuilder<UserState>
) => {
  builder
    .addCase(getPayments.pending, (state: UserState) => {
      state.isPaymentsLoading = true
      state.isPaymentsReady = false
    })
    .addCase(
      getPayments.fulfilled,
      (state: UserState, action: PayloadAction<any>) => {
        const { data, hasMore } = action.payload

        return {
          ...state,
          paymentsData: data,
          isPaymentsLoading: false,
          isPaymentsReady: true,
        }
      }
    )
    .addCase(getPayments.rejected, (state, action) => {
      state.hasError = true
      state.isPaymentsLoading = false
      state.errorMessage = action.payload
        ? action.payload.errorMessage
        : action.error.message
    })
}
