import { InfoCardType } from "types/uiBaseType"

export const baseInfoBoxStyle = ({
  height = "auto",
  width = "100%",
  themeBgColor = "primary.main",
  fontSize = "1rem",
  boxShadow = 1,
  fontFamily = "Roboto",
  borderRadius = "10px",
}) => ({
  width: "100%",
  height,
  backgroundColor: themeBgColor,
  align: "center",
  boxShadow,
  borderRadius,
  fontSize,
  fontFamily,
})

export const buttonStyle = ({
  height = "40px",
  width = "100%",
  themeBgColor,
  borderRadius,
  fontSize,
}) => ({
  ...baseInfoBoxStyle({
    boxShadow: 0,
    fontSize,
    height,
    themeBgColor,
    borderRadius,
    width,
  }),
})

export const welcomeOptionsStyle = (size = "small") => ({
  ...baseInfoBoxStyle({
    boxShadow: 0,
    height: "40px",
    themeBgColor: "primary.main",
    fontSize: size === "small" ? "1rem" : "1.6rem",
    fontFamily: "BananaGrotesk",
  }),
})

export const cardInfoStyle = ({
  themeColor = "#fff",
  type,
  width,
}: {
  themeColor: string
  type: InfoCardType
  width: string
}) => ({
  backgroundColor: themeColor,
  borderRadius: type === "large" ? "24px" : "16px",
  boxShadow: "0px 2px 4px #EDDED1",
  padding: 0,
  width,
})

export const headerInfoStyle = () => ({ fontWeight: 500, fontSize: "1.3rem" })

export const header2InfoStyle = () => ({ fontWeight: 500, fontSize: "0.85rem" })

export const chipStyle = () => ({
  fontSize: "0.85rem",
  marginBottom: "10px",
})
