import axios, { AxiosRequestConfig } from "axios"
import { AxiosRequestMisc } from "../../types/axiosProxy"
import { getConfig } from "../environment"

const envConfig = getConfig()
const useradmin = localStorage.getItem("adminuser")

const commonHeaders = {
  "Content-Type": "application/json; charset=utf-8",
  "X-User": useradmin ? getConfig().userKey : "",
}

export const defaults: AxiosRequestConfig = {
  baseURL: envConfig.apiUrl,
  withCredentials: envConfig.useCredentials,
  headers: commonHeaders,
}

export const suppressBusyUrls = {}

const instance = axios.create()

const validateConfig = {
  validateStatus: (status: number) =>
    (status >= 200 && status < 300) || status === 400,
}

const postTask = async (
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) => {
  const { headers = {}, ...rest } = defaults
  const { headers: configHeaders = {} } = config || {}
  try {
    return await instance.post(url, data, {
      headers: { ...headers, ...configHeaders },
      ...rest,
      ...config,
      ...validateConfig,
    })
  } catch (error) {
    /** */
  }
}

const patchTask = async (
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) => {
  const { headers = {}, ...rest } = defaults
  const { headers: configHeaders = {} } = config || {}
  try {
    return await instance.patch(url, data, {
      headers: { ...headers, ...configHeaders },
      ...rest,
      ...config,
      ...validateConfig,
    })
  } catch (error) {
    /** */
    // logger.error(error)
  }
}

const getTask = async (
  url: string,
  params?: any,
  config?: AxiosRequestConfig,
  misc?: AxiosRequestMisc
) => {
  try {
    return await instance.get(url, {
      params,
      ...defaults,
      ...config,
      ...validateConfig,
    })
  } catch (error) {
    /** */
    //logger.error(error)
  }
}

const deleteTask = async (url: string, config?: AxiosRequestConfig) => {
  const { headers = {}, ...rest } = defaults
  const { headers: configHeaders = {} } = config || {}
  try {
    return await instance.delete(url, {
      headers: { ...headers, ...configHeaders },
      ...rest,
      ...config,
    })
  } catch (error) {
    /** */
    // logger.error(error)
  }
}

const task = (
  action: string,
  url: string,
  params?: any,
  config?: AxiosRequestConfig,
  misc?: AxiosRequestMisc
) => {
  switch (action) {
    case "GET":
      return getTask(url, params, config)
    case "POST":
      return postTask(url, params, config)
    case "PATCH":
      return patchTask(url, params, config)
    case "DELETE":
      return deleteTask(url, config)
    default:
      return null
  }
}

// instance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("accessToken") || ""

//     if (!!token && config.headers)
//       config.headers.Authorization = `Bearer ${token}`

//     return config
//   },
//   (error) => Promise.reject(error)
// )

// instance.interceptors.response.use(
//   (response) => {
//     if (response.status === 200 || response.status === 201) {
//       return response
//     }
//     return Promise.reject(response)
//   },
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       // if token expires do something
//     }

//     return Promise.reject(error)
//   }
// )

const actions = (
  action: string,
  url: string,
  params?: any,
  config?: AxiosRequestConfig,
  misc?: AxiosRequestMisc
): Promise<any> => {
  const result = task(action, url, params, config, misc)
  result?.catch((error) => {
    /** */
    //logger.error(error)
  })

  return result ?? Promise.reject(false)
}

export default actions
