import { combineReducers } from "@reduxjs/toolkit"
import lookupReducer from "./lookup"
import userReducer from "./user"

const rootReducer = combineReducers({
  lookup: lookupReducer,
  user: userReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
