import React from "react"
import { Button } from "@mui/material"
import { buttonStyle } from "../../styles/components/baseStyles"

interface Props {
  readonly disabled?: boolean
  readonly label: string
  readonly size?: "small" | "large" | "medium"
  readonly width?: string
  readonly onClick?: any
  readonly themeBgColor?: string
  readonly height?: string
  readonly borderRadius?: string
  readonly fontSize?: string
  readonly titleIcon?: React.ReactNode
  readonly name?: string
}

const ButtonControl = (props: Props): JSX.Element => {
  const {
    disabled = false,
    width = "100%",
    onClick,
    label,
    size = "large",
    themeBgColor,
    height = "40px",
    borderRadius = "10px",
    fontSize = "1rem",
    titleIcon,
    name = "btn",
  } = props
  return (
    <Button
      component="div"
      disabled={disabled}
      size={size}
      sx={buttonStyle({ height, width, themeBgColor, borderRadius, fontSize })}
      role={name}
      variant="contained"
      onClick={onClick}
    >
      {!!titleIcon && titleIcon}
      <span style={{ marginLeft: "5px" }}>{label}</span>
    </Button>
  )
}

export default ButtonControl
