import React from "react"
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import EventIcon from "@mui/icons-material/Event"
import HomeIcon from "@mui/icons-material/Home"
import LibraryBooks from "@mui/icons-material/LibraryBooks"
import PhotoIcon from "@mui/icons-material/Photo"
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism"
import { useTheme } from "@mui/material"
import BottomNavigation from "@mui/material/BottomNavigation"
import BottomNavigationAction from "@mui/material/BottomNavigationAction"
import Paper from "@mui/material/Paper"
import { getUserRole } from "selectors/userSelector"

const styles = (theme) => ({
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
  },
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
})
const BottomNavbar = (): JSX.Element => {
  const { isAdmin } = useSelector(getUserRole)
  const pathMap = [
    {
      icon: <HomeIcon />,
      label: "Home",
      pathname: "/home",
      visible: true,
    },
    {
      icon: <VolunteerActivismIcon />,
      label: "Donate",
      pathname: "/options",
      visible: true,
    },
    {
      icon: <EventIcon />,
      label: "Events",
      pathname: "/events",
      visible: true,
    },
    // {
    //   icon: <PhotoIcon />,
    //   label: "Gallery",
    //   pathname: "/gallery",
    //   visible: true,
    // },
    // {
    //   icon: <LibraryBooks />,
    //   label: "Report",
    //   pathname: "/report",
    //   visible: isAdmin,
    // },
  ]

  const theme = useTheme()
  return (
    <BottomNavigation
      showLabels
      component={Paper}
      elevation={3}
      sx={styles(theme)}
    >
      {pathMap
        .filter((item: any) => item.visible)
        .map((item) => (
          <BottomNavigationAction
            key={item.pathname}
            component={NavLink}
            icon={item.icon}
            label={item.label}
            sx={{
              "&.active": {
                color: "action.selected",
              },
              //  display: item.visible ? "block" : "none",
            }}
            to={item.pathname}
          />
        ))}
    </BottomNavigation>
  )
}
export default BottomNavbar
