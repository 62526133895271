import React from "react"

const PayPalForm = () => {
  return (
    <form
      id="paypalform"
      action="https://www.paypal.com/donate"
      method="post"
      target="_top"
    >
      <input type="hidden" name="hosted_button_id" value="EYKW4VV2ZGHLN" />
    </form>
  )
}

export default PayPalForm
