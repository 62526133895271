import { configureStore, Action } from "@reduxjs/toolkit"
import rootReducer, { RootState } from "reducers"
import { ThunkAction } from "redux-thunk"

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
})

export type AppDispatchType = typeof store.dispatch
export const AppDispatch = store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store
