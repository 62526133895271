import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import { HTTPAxiosProxy } from "types/axiosProxy"
import actions from "./axios.actions"

const instance = axios.create()

const http: HTTPAxiosProxy = {
  get: async (url, params?, config?, misc?) => {
    return actions("GET", url, params, config, misc)
  },
  post: async (url, data?: any, config?) => {
    return actions("POST", url, data, config)
  },
  patch: async (url, data?: any, config?) => {
    return actions("PATCH", url, data, config)
  },
  delete: async (url: string, config?: AxiosRequestConfig) => {
    return actions("DELETE", url, null, config)
  },
  proxied: instance,
}

// enum StatusCode {
//   Unauthorized = 401,
//   Forbidden = 403,
//   TooManyRequests = 429,
//   InternalServerError = 500,
// }

// const headers: Readonly<Record<string, string | boolean>> = {
//   Accept: "application/json",
//   "Content-Type": "application/json; charset=utf-8",
//   "Access-Control-Allow-Credentials": true,
//   "X-Requested-With": "XMLHttpRequest",
// };

// // We can use the following function to inject the JWT token through an interceptor
// // We get the `accessToken` from the localStorage that we set when we authenticate
// const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
//   try {
//     const token = localStorage.getItem("accessToken");

//     if (token != null) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   } catch (error) {
//     throw new Error(error);
//   }
// };

// class Http {
//   private instance: AxiosInstance | null = null;

//   private get http(): AxiosInstance {
//     return this.instance != null ? this.instance : this.initHttp();
//   }

//   initHttp(url:string) {
//     const http = axios.create({
//       baseURL: url,
//       headers,
//       withCredentials: true,
//     });

//     http.interceptors.request.use(injectToken, (error) => Promise.reject(error));

//     http.interceptors.response.use(
//       (response) => response,
//       (error) => {
//         const { response } = error;
//         return this.handleError(response);
//       }
//     );

//     this.instance = http;
//     return http;
//   }

//   request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
//     return this.http.request(config);
//   }

//     get<T = any, P = any, R = AxiosResponse<T>>(url: string, params?:P,  config?: AxiosRequestConfig): Promise<R> {
//         return this.http.get<T, R>(url, config);
//     }

//   post<T = any, R = AxiosResponse<T>>(
//     url: string,
//     data?: T,
//     config?: AxiosRequestConfig
//   ): Promise<R> {
//     return this.http.post<T, R>(url, data, config);
//   }

//   put<T = any, R = AxiosResponse<T>>(
//     url: string,
//     data?: T,
//     config?: AxiosRequestConfig
//   ): Promise<R> {
//     return this.http.put<T, R>(url, data, config);
//   }

//   delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
//     return this.http.delete<T, R>(url, config);
//   }

//   // Handle global app errors
//   // We can handle generic app errors depending on the status code
//   private handleError(error) {
//     const { status } = error;

//     switch (status) {
//       case StatusCode.InternalServerError: {
//         // Handle InternalServerError
//         break;
//       }
//       case StatusCode.Forbidden: {
//         // Handle Forbidden
//         break;
//       }
//       case StatusCode.Unauthorized: {
//         // Handle Unauthorized
//         break;
//       }
//       case StatusCode.TooManyRequests: {
//         // Handle TooManyRequests
//         break;
//       }
//     }

//     return Promise.reject(error);
//   }
//}

export const suppressBusyUrls = {}

export default http
