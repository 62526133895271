import React from "react"
import { Stack } from "@mui/material"

const Gallery = () => {
  return (
    <Stack spacing={1} sx={{ alignItems: "center" }}>
      <>test</>
    </Stack>
  )
}

export default Gallery
