export interface EnvironmentConfig {
  environment: string
  apiUrl: string
  useCredentials: boolean
  googleClientId: string
  stripePublishedKey: string
  userKey: string
  adminName: string
  adminPwd: string
  paypalClientId: string
  paypalSc: string
}

export const getConfig = (): EnvironmentConfig => ({
  environment: process.env.REACT_APP_ENVIRONMENT || "",
  apiUrl: process.env.REACT_APP_REST_URL || "",
  useCredentials:
    (process.env.REACT_APP_REST_USE_CREDENTIALS || "").toLowerCase() === "true",
  googleClientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || "",
  stripePublishedKey: process.env.REACT_APP_STRIP_PUBLISHED_KEY || "",
  userKey: process.env.REACT_APP_USER_KEY || "",
  adminName: process.env.REACT_APP_ADMIN_NAME || "",
  adminPwd: process.env.REACT_APP_ADMIN_PWD || "",
  paypalClientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || "",
  paypalSc: process.env.REACT_APP_PAYPAL_CLIENT_SECRET || "",
})
