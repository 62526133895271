import { getClientSecret } from "services/paymentService"
import { UserState } from "./user"
import type { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit"

export const clientSecretBuilder = (
  builder: ActionReducerMapBuilder<UserState>
) => {
  builder
    .addCase(getClientSecret.pending, (state: UserState) => {
      state.isClientSecretLoading = true
      state.isClientSecretReady = false
    })
    .addCase(
      getClientSecret.fulfilled,
      (state: UserState, action: PayloadAction<any>) => {
        const { clientSecret } = action.payload

        return {
          ...state,
          data: { ...state.data, clientSecret },
          isClientSecretLoading: false,
          isClientSecretReady: true,
        }
      }
    )
    .addCase(getClientSecret.rejected, (state, action) => {
      state.hasError = true
      ;(state.isClientSecretLoading = false),
        (state.errorMessage = action.payload
          ? action.payload.errorMessage
          : action.error.message)
    })
}
