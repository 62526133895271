import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material"
import ButtonControl from "components/controls/ButtonControl"
import InfoCard from "components/controls/InfoCard"
import { updateUserProfile } from "reducers/user"
import { getConfig } from "utils/environment"
import { AppDispatch } from "utils/stateManagement/store"

const validationRules = {
  userName: { required: true },
  pwd: { required: true },
}

const Admin = (): JSX.Element => {
  const navigate = useNavigate()

  const [form, setForm] = React.useState<any>({
    userName: "",
    pwd: "",
  })

  useEffect(() => {
    const adminUser = localStorage.getItem("adminuser")
    if (adminUser) {
      const userObj = JSON.parse(adminUser)
      setForm(userObj)
    }
  }, [])
  const onClick = React.useCallback(() => {
    const userObj = {
      userName: form.userName,
      isAdmin: true,
    }

    const isAdmin =
      form.userName === getConfig().adminName &&
      form.pwd === getConfig().adminPwd
    if (isAdmin) {
      localStorage.setItem("adminuser", JSON.stringify(userObj))
      AppDispatch(updateUserProfile(userObj))
      location.reload()
    }
  }, [form])

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      const name = e.target.name
      const rule = validationRules[name]
      let valid = true
      if (rule) {
        if (rule.required) {
          if (!value) valid = false
        }
      }

      setForm((prevState) => ({
        ...prevState,
        [name]: value,
        disabled: !valid,
      }))
    },
    []
  )

  const onkeyDown = (e: any, maxLength: number) => {
    if (e.target.value.length > maxLength) {
      e.nativeEvent.preventDefault()
    }
  }

  return (
    <InfoCard>
      <Stack direction="column" spacing={2}>
        <Stack>
          <TextField
            required
            label="User Name"
            name="userName"
            type="text"
            value={form.userName || ""}
            variant="outlined"
            onChange={onChange}
            onKeyDown={(e: any) => {
              onkeyDown(e, 70)
            }}
          />
        </Stack>
        <Stack>
          <TextField
            required
            label="Pwd"
            name="pwd"
            value={form.pwd || ""}
            variant="outlined"
            onChange={onChange}
            onKeyDown={(e: any) => {
              onkeyDown(e, 70)
            }}
          />
        </Stack>
        <ButtonControl label="Login" width="100px" onClick={onClick} />
      </Stack>
    </InfoCard>
  )
}

export default React.memo(Admin)
