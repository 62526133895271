import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { DataStatus } from "../types/uiBaseType"
import { UserDataType } from "../types/user"
import { clientSecretBuilder } from "./clientSecretBuilder"
import { customersBuilder } from "./customersBuilder"
import { paymentsBuilder } from "./paymentsBuilder"

export interface UserState extends DataStatus {
  data: UserDataType
  paymentsData?: any[]
  customersData?: any[]
}

const initialState: UserState = {
  data: { userProfile: {} },
  isLoaded: false,
  paymentsData: [],
  customersData: [],
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserProfile: (state: UserState, action: PayloadAction<any>) => {
      const { name, email, requireReceipt } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          userProfile: {
            ...state.data.userProfile,
            email,
            name,
          },
          requireReceipt,
        },
      }
    },
    updateUserRole: (state: UserState, action: PayloadAction<any>) => {
      const { userName, isAdmin } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          userProfile: {
            ...state.data.userProfile,
            userName,
            isAdmin,
          },
        },
      }
    },
    updateDonation: (state: UserState, action: PayloadAction<any>) => {
      const { amount, currency } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          amount,
          currency,
        },
      }
    },
  },
  extraReducers: (builder) => {
    clientSecretBuilder(builder)
    paymentsBuilder(builder)
    customersBuilder(builder)
    // builder
    //   .addCase(
    //     updateUser.fulfilled,
    //     (state: UserState, action: PayloadAction<any>) => {
    //       const {
    //         id,
    //         firstname,
    //         lastname,
    //         address1,
    //         address2,
    //         zipcode,
    //         country,
    //         email,
    //       } = action.payload
    //       return {
    //         ...state,
    //         data: {
    //           ...state.data,
    //           userProfile: {
    //             ...(state.data.userProfile || {}),
    //             id,
    //             firstname,
    //             lastname,
    //             address1,
    //             address2,
    //             zipcode,
    //             country,
    //             email,
    //           },
    //         },
    //         isLoading: false,
    //         newUpdates: false,
    //       }
    //     }
    //   )
    //   .addCase(updateUser.rejected, (state, action) => {
    //     state.hasError = true
    //     state.errorMessage = action.payload
    //       ? action.payload.errorMessage
    //       : action.error.message
    //   })
    //   .addCase(getUserById.pending, (state, action) => {
    //     state.isLoading = true
    //   })
    //   .addCase(getUserByEmail.pending, (state, action) => {
    //     state.isLoading = true
    //   })
    //   .addCase(
    //     getUserByEmail.fulfilled,
    //     (state: UserState, action: PayloadAction<any>) => {
    //       const {
    //         id,
    //         email,
    //         firstname,
    //         lastname,
    //         address1,
    //         address2,
    //         zipcode,
    //         country,
    //       } = action.payload
    //       return {
    //         ...state,
    //         data: {
    //           ...state.data,
    //           userProfile: {
    //             ...(state.data.userProfile || {}),
    //             id,
    //             email,
    //             firstname,
    //             lastname,
    //             address1,
    //             address2,
    //             zipcode,
    //             country,
    //           },
    //         },
    //         isLoaded: true,
    //         isLoading: false,
    //       }
    //     }
    //   )
    //   .addCase(
    //     getUserById.fulfilled,
    //     (state: UserState, action: PayloadAction<any>) => {
    //       const {
    //         id,
    //         email,
    //         firstname,
    //         lastname,
    //         address1,
    //         address2,
    //         zipcode,
    //         country,
    //       } = action.payload
    //       return {
    //         ...state,
    //         data: {
    //           ...state.data,
    //           userProfile: {
    //             ...(state.data.userProfile || {}),
    //             id,
    //             email,
    //             firstname,
    //             lastname,
    //             address1,
    //             address2,
    //             zipcode,
    //             country,
    //           },
    //         },
    //         isLoaded: true,
    //         isLoading: false,
    //       }
    //     }
    //   )
  },
})

export const { updateUserProfile, updateDonation, updateUserRole } =
  userSlice.actions
export default userSlice.reducer
