import React from "react"
import { Card, CardContent, CardHeader } from "@mui/material"
import { cardInfoStyle } from "styles/components/baseStyles"
import { InfoCardType } from "types/uiBaseType"

interface Props {
  readonly width?: string
  readonly minHeight?: string
  readonly height?: string
  readonly themeColor?: string
  readonly children?: React.ReactNode
  readonly margin?: string
  readonly title?: string
  readonly type?: InfoCardType
}

const InfoCard = (props: Props): JSX.Element => {
  const {
    margin = "0",
    width = "100%",
    minHeight,
    themeColor = "#fff",
    children,
    title = "",
    height = "auto",
    type = "large",
  } = props

  return (
    <Card sx={cardInfoStyle({ themeColor, type, width })} variant="elevation">
      {!!title && (
        <CardHeader
          sx={{ textAlign: "center", paddingBottom: "0" }}
          title={title}
        />
      )}
      <CardContent>{children}</CardContent>
    </Card>
  )
}

export default InfoCard
